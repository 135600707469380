$progress-height: 50px;
$timestamp-width: 100px;
$progress-width: calc(100vw - #{$timestamp-width});
$canvas-height: calc(100 * var(--vh) - #{$progress-height});
$canvas-height-fallback: calc(100vh - #{$progress-height});

.label {
    margin-top: -1em;
    color: rgba(255,255,255,0.8);
    background-color: rgba(0, 0, 0, 0.5);
    font-family: sans-serif;
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
    font-size: 0; /* A non-zero font size can cause unwanted spacing between elements */
    height: 100vh;
    overflow: hidden;
    position: fixed;
    background-color: black;
    font-family: sans-serif;
}

@mixin slider-thumb {
    border: 1px solid rgba(255,255,255,0.5);
    width: 1px; /* Set a specific slider handle width */
    height: $progress-height; /* Slider handle height */
    background: rgba(0,0,0,1); /* Green background */
    cursor: pointer; /* Cursor on hover */
    box-sizing: content-box;
}

/* The slider itself */
input[type=range] {
    -webkit-appearance: none;  /* Override default CSS styles */
    appearance: none;
    width: $progress-width; /* Full-width */
    height: $progress-height; /* Specified height */
    outline: none; /* Remove outline */
    opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
    -webkit-transition: .1s; /* 0.2 seconds transition on hover */
    transition: opacity .2s;
    border-radius: 0;
    background-size: 100% 100%;
    line-height: $progress-height;
    float: left;
}

/* Mouse-over effects */
input[type=range]:hover { opacity: 1; /* Fully shown on mouse-over */ }
input[type=range]:focus { outline: none; /* Removes the blue border. You should probably do some kind of focus styling for accessibility reasons though. */ }
input[type=range]::-ms-thumb { @include slider-thumb(); }
input[type=range]::-moz-range-thumb { @include slider-thumb(); }
input[type=range]::-webkit-slider-thumb { @include slider-thumb(); -webkit-appearance: none; appearance: none; }
input[type=range]::-ms-fill-lower       { background: transparent; }
input[type=range]:focus::-ms-fill-lower { background: transparent; }
input[type=range]::-ms-fill-upper       { background: transparent; }
input[type=range]:focus::-ms-fill-upper { background: transparent; }
input[type=range]::-ms-track {
    width: 100%;
    cursor: pointer;
  
    /* Hides the slider so custom styles can be added */
    background: transparent; 
    border-color: transparent;
    color: transparent;
}
#canvas-3d {
    height: $canvas-height-fallback;
    height: $canvas-height;
    width: 100vw;
}

#canvas-3d, input[type=range] {
    margin: 0;
    padding: 0;
}

#footer {
    width: 100vw;
    height: $progress-height;
}

#timestamp {
    left: $progress-width;
    height: $progress-height;
    width: $timestamp-width;
    background-color: #111111;
    color: white;
    float: left;
    justify-content: center;
    display: flex;
    align-items: center;
    cursor: pointer;
    font-size: 16px;
}